import Subheader from '@/components/careers/subheader/Subheader.vue'
import EmployeeComments from '@/components/careers/EmployeeComments.vue'
import CardRow from '@/components/card-row/CardRow.vue'
import OpenPositions from '@/components/careers/OpenPositions.vue'
import JoinRevolution from '@/components/careers/JoinRevolution.vue'

export default {
	name: 'Careers',
	components: {
		Subheader,
		EmployeeComments,
		CardRow,
		OpenPositions,
		JoinRevolution
	},
	metaInfo () {
		return {
			title: 'Career',
			meta: [{
				name: 'description',
				content: 'Topic description about "Career" page',
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Career',
			},
			{
				name: 'og:description',
				content: 'Topic description about "Career" page',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Career',
			},
			{
				name: 'twitter:description',
				content: 'Topic description about "Career" page',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
	computed: {
		items () {
			return [
				{
				src: require('@/assets/images/working-env-icon.svg'),
				title: this.trans('careers_work_condition_1_title'),
				description: this.trans('careers_work_condition_1_desc'),
				},
				{
				src: require('@/assets/images/flex-icon.svg'),
				title: this.trans('careers_work_condition_2_title'),
				description: this.trans('careers_work_condition_2_desc'),
				},
				{
				src: require('@/assets/images/growth-icon.svg'),
				title: this.trans('careers_work_condition_3_title'),
				description: this.trans('careers_work_condition_3_desc'),
				},
				{
				src: require('@/assets/images/benefit-icon.svg'),
				title: this.trans('careers_work_condition_4_title'),
				description: this.trans('careers_work_condition_4_desc'),
				}
			]
		}
	}
}
