<template>
  <div class="content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" align-self="center">
        <p
					class="vue-editor text-md-h2 text-body-1 text-center font-weight-black"
					v-html="trans('careers_page_join_title')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-center">
        <JoinRevolutionBtn />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import JoinRevolutionBtn from '@/components/careers/careers-buttons/JoinRevolutionBtn'

export default {
  name: 'JoinRevolution',
  components: {
    JoinRevolutionBtn
  }
}
</script>

<style lang="scss" scoped>
.sub-title {
  max-width: 667px;
}
</style>
