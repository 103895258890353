import ApiBase from '@/api/base'

class ApiEmployeeComments {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/employee-comments`)
      .then(response => {
        ApiBase.setToStore(null, 'setEmployeeComments', response.data)
      })
  }
}

export default ApiEmployeeComments
