<template>
	<v-btn
		:ripple="false"
		:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
		depressed
		:small="$vuetify.breakpoint.smAndDown"
		class="black-btn"
		href="mailto:hr@1tn.com"
	>
		<span
			class="vue-editor font-weight-bold px-md-7 px-8 white--text"
			v-html="trans('careers_page_last_btn')"
		/>
	</v-btn>
</template>

<script>
export default {
	name: 'JoinRevolutionBtn'
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}

</style>
