<template>
<div v-if="state.employeeComments && state.employeeComments.length" class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="vue-editor title text-md-h2 text-subtitle-1 font-weight-black mb-5"
					v-html="trans('careers_page_employee_comments_title')"
				/>
			</v-col>
			<v-col cols="12">
				<v-carousel
					v-model="model"
					:prev-icon="false"
					height="100%"
					hide-delimiter-background
					class="slider"
					:delimiter-icon="state.employeeComments === model ? '' : '$delimiter'"
				>
					<template v-slot:next="{ on }">
						<v-btn
							v-on="on"
							minWidth="36"
							class="next-btn pa-0"
						>
							<v-img
								:width="$vuetify.breakpoint.smAndDown ? '24' : '36'"
								:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
								contain
								src="@/assets/images/next-arrow-slider.png"
							/>
						</v-btn>
					</template>
					<v-carousel-item
						v-for="comment in state.employeeComments"
						:key="comment.id"
					>
						<v-row no-gutters>
							<v-col cols="12" class="carousel-content">
								<div class="d-flex align-md-center flex-column flex-md-row mr-md-15">
									<div class="image-back main-green--background flex-shrink-0 mr-md-6">
										<v-sheet
											v-if="comment.image && comment.imageUrl"
											:width="$vuetify.breakpoint.smAndDown ? '190' : '322'"
											:height="$vuetify.breakpoint.smAndDown ? '190' : '322'"
											class="image-content"
										>
											<v-img width="100%" height="100%" :src="comment.imageUrl" />
										</v-sheet>
									</div>
								<div class="text-block mt-10 mt-md-0">
									<h4
										v-if="comment.employee"
										class="vue-editor text-subtitle-1 text-lg-h4 font-weight-bold mb-4 mb-lg-6"
										v-html="comment.employee"
									/>
									<span
										v-if="comment.position"
										class="vue-editor d-block text-body-1 text-lg-subtitle-1 grayText--text mb-6 mb-lg-9"
										v-html="comment.position"
									/>
									<p
										v-if="comment.message"
										class="vue-editor text-body-2 text-lg-subtitle-1 grayText--text"
										v-html="comment.message"
									/>
								</div>
							</div>
							</v-col>
						</v-row>
					</v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row>
</div>
</template>

<script>
import ApiEmployeeComments from '@/api/employee-comments'

export default {
	name: 'EmployeeComments',
	data: () => ({
		model: 0
	}),
	created () {
		ApiEmployeeComments.getAll(this)
	}
}
</script>

<style lang="scss" scoped>
.content {
	margin-top: 75px;
}
.title {
	margin-bottom: 120px !important;
}
.carousel-content {
	margin-bottom: 110px;
}
.image-wrapper {
	position: relative;
}
.image-back {
	position: relative;
	width: 342px;
	height: 342px;
	border-radius: 50%;
}
.image-content {
	position: absolute;
	border-radius: 50%;
	margin: 10px;
	overflow: hidden;
}
.text-block {
	max-width: 560px;
}
.next-btn {
	background: transparent !important;
	box-shadow: none !important;
	top: 0px !important;
}
.slider::v-deep() {
	.v-carousel__controls {
		height: 30px !important;
	}
	.v-window__next {
		top: calc((100% - 140px) / 2) !important;
	}
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.title {
		margin-bottom: 40px !important;
	}
	.carousel-content {
		margin-bottom: 60px;
	}
	.image-back {
		width: 200px;
		height: 200px;
		border-radius: 50%;
	}
	.image-content {
		position: absolute;
		border-radius: 50%;
		margin: 5px;
	}
	.slider::v-deep() {
		.v-window__next {
			top: 80px !important;
			margin-right: 11px !important;
		}
	}
}
</style>
