<template>
  <v-btn
    :ripple="false"
		minWidth="170"
    :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
    depressed
    :small="$vuetify.breakpoint.smAndDown"
    class="black-btn px-md-9 px-11"
		@click="goToPage(slug)"
	>
    <span
			class="vue-editor font-weight-bold white--text"
			v-html="trans('current_position_learn_more')"
		/>
  </v-btn>
</template>

<script>

export default {
	name: 'LearnMore',
	props: {
		slug: {
			required: true
		}
	},
	methods: {
		goToPage (slug) {
			this.$router.push({
				name: 'CurrentPosition',
				params: {
					slug
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.black-btn {
	margin-left: auto;
	margin-right: auto;
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}
</style>
