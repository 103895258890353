<template>
  <v-btn
    :ripple="false"
    :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
    depressed
    :small="$vuetify.breakpoint.smAndDown"
    class="black-btn pa-0"
		@click="scrollToSection"
	>
    <span
			class="vue-editor font-weight-bold px-md-6 px-8 white--text"
			v-html="trans('careers_work_view_positions')"
    />
  </v-btn>
</template>

<script>
export default {
name: 'ViewPositions',
methods: {
	scrollToSection () {
		let section = document.querySelector('.open-positions-section')
		if (!section) return
    section.scrollIntoView({block:'start', behavior:'smooth'});
	}
}
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}
</style>
