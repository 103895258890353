<template>
  <div class="open-positions-section content">
    <v-row no-gutters class="flex-column">
      <v-col cols="12" md="7">
        <p
					class="vue-editor title text-md-h2 text-body-1 font-weight-black"
					v-html="trans('careers_page_open_position_title')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-wrap list">
        <v-sheet
          v-for="position in state.openPositions"
          :key="position.id"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '480'"
          :minHeight="$vuetify.breakpoint.smAndDown ? '273' : '333'"
          class="d-flex flex-column justify-space-between pa-6 px-md-5 py-md-10 sheet-item"
        >
					<div class="d-flex flex-column">
						<h4
							class="vue-editor text-body-1 text-md-subtitle-2 font-weight-bold mb-3 mb-md-5"
							v-html="position.title"
						/>
            <span class="text-body-2 graytext--text mb-6 mb-md-8">
              {{ position.gender }}, {{ position.work_arrangement }}
            </span>
            <p
							v-html="position.short_description"
							class="vue-editor text-body-2 text-md-body-1 graytext--text"
						/>
					</div>
					<LearnMore
						class="mt-6 mt-md-9"
						:slug="position.slug"
					/>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiOpenPositions from '@/api/open-positions'
import LearnMore from '@/components/btns/LearnMore.vue'

export default {
  name: 'OpenPositions',
  components: {
    LearnMore
  },
	created() {
    ApiOpenPositions.getAll(this)
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 80px !important;
}
.list {
  gap: 50px;
}
.sheet-item {
  box-shadow: 0px 20px 40px #2d343629;
  border-radius: 40px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .title {
    margin-bottom: 40px !important;
  }
}
</style>
